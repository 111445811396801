<template>
  <section class="PowerofSynergySection">
    <h3 class="heading" v-html="PowerofSynergyData.Title"></h3>
    <h4 class="subHeading" v-html="PowerofSynergyData.Description"></h4>
    <div class="synergyList">      
      <div v-for="(item, index) in PowerofSynergyData.Boxsection" v-bind:key="index" class="synergyList__box">
          <div class="synergyList__boxImage">
            <img :src="item.image" :alt="item.title"/>
          </div>
          <div class="synergyList__boxContent">
            <h3 v-html="item.title"></h3>
            <p v-html="item.description"></p> 
            <ul v-if="item.listing.length > 0">
                <li v-for="(item, index) in item.listing" v-html="item" v-bind:key="index"></li>
            </ul>
          </div>
        </div>    
    </div>
    <div class="callOutBox">
      <div class="callOutBox__icon"><img src="@/Assets/Img/Icons/icon-pain-model-badge.svg" alt="" /></div>
      <p v-html="PowerofSynergyData.Boxtitle"></p>
      <a class="btn" :href="PowerofSynergyData.BoxLink">Explore the data<img src="@/Assets/Img/Icons/icon-arrow-right-circle.svg" alt="Right arrow" /></a>
    </div>
    <div class="harnessBox">
      <div class="harnessBox__title">
        <div class="harnessBox__titleOne" v-html="PowerofSynergyData.Quotedata1"></div>
        <div class="harnessBox__titleTwo" v-html="PowerofSynergyData.Quotedata2"></div>
      </div>
      <div class="harnessBox__image">
        <img :src="PowerofSynergyData.Quoteimage" alt="COMBOGESIC® IV vial pack" />
      </div>
    </div>
  </section>
</template>

<script>
import ClickAnalytics from "@/mixins/ClickAnalytics";
export default {
  name: "PowerofSynergySection",
  mixins: [ClickAnalytics],
  props: {
    PowerofSynergyData: Object,
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  }
};
</script>

<style lang="scss" src="./PowerofSynergySection.scss" scoped></style>