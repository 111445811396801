<template>
  <section class="EfficacySection">
    <div class="EfficacySection__list" v-for="(item, index) in EfficacyData" v-bind:key="index" :id="item.id"
      :class="'EfficacySection__list' + index">
      <div class="EfficacySection__listBox">
        <div class="EfficacySection__listBoxImage">
          <img :src="item.image" :alt="item.Title" />
        </div>
        <div class="EfficacySection__listBoxContent">
        <h3 class="heading" v-html="item.Title"></h3>
        <p class="des" v-html="item.description"></p>
        <ul class="list">
          <li v-for="(item, index) in item.listing" v-html="item" v-bind:key="index"></li>
        </ul>
      </div>
      </div>      
      <div class="callOutBox" v-if="item.boxdata">
          <div class="callOutBox__icon"><img src="@/Assets/Img/Icons/icon-pain-model-badge.svg" alt="" /></div>
          <p v-html="item.boxdata"></p>
      </div>
      <div class="graphContainer" v-if="item.graphdata.length > 0">
        <div class="graphBox" v-for="(graphitem, index) in item.graphdata" v-bind:key="index">
          <div class="graphBox__title"><span  v-html="graphitem.title"></span></div>
          <div class="graphBox__columns">
            <div class="graphBox__columnLeft">
              <img :src="graphitem.image" :alt="graphitem.altText" />
            </div>
            <div class="graphBox__columnRight">
              <ul class="list">
                <li v-for="(item, index) in graphitem.listing" v-html="item" v-bind:key="index"></li>
              </ul>
            </div>
          </div>
          <div class="comparableSafety">
            <h4 v-html="graphitem.subtitle"></h4>
            <ul class="list">
              <li v-for="(item, index) in graphitem.listing2" v-html="item" v-bind:key="index"></li>
            </ul>
          </div>
          <div class="callOutBox" v-if="graphitem.boxdata">
            <div class="callOutBox__icon">
              <img src="@/Assets/Img/Icons/icon-pain-model-badge.svg" alt="" />
            </div>
            <p v-html="graphitem.boxdata"></p>
          </div>
          <ul class="footnote">
            <li v-for="(item, index) in graphitem.footnote" v-html="item" v-bind:key="index"></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ClickAnalytics from "@/mixins/ClickAnalytics";
export default {
  name: "EfficacySection",
  mixins: [ClickAnalytics],
  props: {
    EfficacyData: Array,
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  }
};
</script>

<style lang="scss" src="./EfficacySection.scss" scoped></style>