<template src="./AnalgesicEfficacy.html"></template>

<script>
import { mapState } from "vuex";
import ScrollDepthAnalytics from "@/mixins/ScrollDepthAnalytics";
import InnerHeroSection from "@/Components/Blocks/InnerHeroSection/InnerHeroSection";
import PainModelSection from "@/Components/Blocks/PainModelSection/PainModelSection";
import EfficacySection from "@/Components/Blocks/EfficacySection/EfficacySection";
import StudyDesignSection from "@/Components/Blocks/StudyDesignSection/StudyDesignSection";
import HomePageNavSection from "@/Components/Blocks/HomePageNavSection/HomePageNavSection";

export default {
  name: "AnalgesicEfficacy",
  components: {
    InnerHeroSection,
    PainModelSection,
    EfficacySection,
    StudyDesignSection,
    HomePageNavSection,
  },
  mixins: [ScrollDepthAnalytics],
  data() {
    return {
      HeroBoxData: {
          title:"THE COMBOGESIC<sup>®</sup> IV PAIN MODEL<sup>&copy;</sup>",
      },
      PainModelData:{
        Title: "COMBOGESIC® IV (acetaminophen and ibuprofen) delivers the advantages of multimodal, non-opioid analgesia while offering an advanced approach to adult pain management<sup>1</sup>",
        Subtitle:"The clinical benefits of COMBOGESIC® IV are featured in the Pain Model<sup>&copy;</sup>:",
        footnote:["<sup class='astrick'>*</sup>A superior analgesic effect of COMBOGESIC® IV was observed during a single dosing interval (SPID<sub>6</sub> and TOTPAR<sub>6</sub>) and at most scheduled time points.<span>1</span>","AE=adverse event; SPID<sub>6</sub>=Sum of Pain Intensity Differences over 6 hours; TOTPAR<sub>6</sub>=Total Pain Relief over 6 hours."],
        Boxsection:[
          {
            title:"Shorter Onset to Analgesia",
            image: require("@/Assets/Img/Icons/icon-pain-model-01.svg"),
            link:"/#/analgesic-efficacy/#shorter-onset-to-analgesia"
          },
           {
            title:"Superior Analgesia Efficacy. Comparable Safety in Common AEs",
            image: require("@/Assets/Img/Icons/icon-pain-model-02.svg"),
            link:"/#/analgesic-efficacy/#superior-efficacy"
          },
           {
            title:"Sustained Pain Management Results*",
            image: require("@/Assets/Img/Icons/icon-pain-model-03.svg"),
            link:"/#/analgesic-efficacy/#sustained-results"
          },
        ],
        Boxtitle:"Evidence suggests that less than half of patients who undergo surgery report adequate postoperative pain relief11",
        Boximage: require("@/Assets/Img/Icons/icon-50-percent.svg"),
        Quotedata: "There is a need for a cultural transformation in the way pain is viewed and treated.",
        QuateCompany:"Institute of Medicine, 2011",
      },
      EfficacyData:[
        {
          Title:"Shorter Onset to Analgesia",
          image: require("@/Assets/Img/Icons/icon-pain-model-01.svg"),
          description:"",
          listing:["<span class='primary-color semi-bold'>Time to meaningful pain relief</span> and <span class='primary-color semi-bold'>onset time to analgesia were shorter</span> in the COMBOGESIC® IV group than in the ibuprofen IV and placebo groups<sup>1</sup>","<span class='primary-color semi-bold'>Time to perceptible pain relief was shorter</span> in the COMBOGESIC® IV group than in the acetaminophen IV and placebo groups<sup>1</sup>","COMBOGESIC® IV provided a <span class='primary-color semi-bold'>more rapid, statistically significant analgesic effect</span> compared with either individual constituent alone or placebo<sup>1</sup>"],
          boxdata:"COMBOGESIC® IV is studied as a <strong><u>more effective</u></strong> pain management tool<sup>1,2</sup>",
          graphdata:[],
          id:"shorter-onset-to-analgesia"
        },
         {
          Title:"Superior Analgesia Efficacy. Comparable Safety in Common AEs",
          image: require("@/Assets/Img/Icons/icon-pain-model-02.svg"),
          description:"COMBOGESIC® IV allows for superior analgesic efficacy and comparable safety in common AEs versus ibuprofen IV or acetaminophen IV alone<sup>3‡</sup>",
          listing:[],
          boxdata:"",
          graphdata:[{
            title:"Primary Endpoint: <br/ class='line-break'> Time-adjusted SPID<sub>48</sub> With Pre-Rescue VAS Score Carried Forward Up to 2 Hours (<i>P</i><0.001)",
            image: require("@/Assets/Img/Efficacy/img-primary-endpoint.png"),
            altText: "Primary endpoint chart: Time-adjusted Sum of Pain Intensity Differences over 48 hours",
            listing:["COMBOGESIC® IV offered the <span class='primary-color semi-bold'>greatest reduction in pain</span> over the 48-hour study period compared with the individual components and placebo<sup>3‡</sup>","<span class='primary-color semi-bold'>MORE THAN DOUBLE pain relief</span> than that of acetaminophen IV and ibuprofen IV alone<sup>1‡</sup>","The <span class='primary-color semi-bold'>superior analgesic effect</span> was maintained in subgroup analyses according to sex, age, race, and American Society of Anesthesiology Physical Status Classification<sup>1</sup>"],
            subtitle:"Comparable Safety",
            listing2:["The incidence of common AEs was largely comparable between the COMBOGESIC® IV group and the other active treatment groups<sup>1</sup>","There was no difference in the rate of nausea or dizziness between the COMBOGESIC® IV group and the comparators<sup>1</sup>"],
            boxdata:"",
            footnote:["<sup>&ddagger;</sup>Based on time-adjusted Sum of Pain Intensity Differences over 48 hours (SPID<sub>48</sub>), calculated from Visual Analogue Scale (VAS) pain intensity scores recorded up until the time of consumption of the first dose of rescue."]
          }],
          id:"superior-efficacy"
         },
         {
          Title:"Sustained Pain Management Results",
          image: require("@/Assets/Img/Icons/icon-pain-model-03.svg"),
          description:"Superior analgesic effect over a single dosing interval and multiple dosing periods<sup>1&sect;</sup>",
          listing:[],
          boxdata:"",
          graphdata:[{
            title: "Secondary Endpoint: <br/ class='line-break'> VAS Pain Intensity Scores Over the 48-Hour Treatment Period",
            altText: "Secondary endpoint chart: Visual Analogue Scale pain intensity scores over 48 hours",
            image: require("@/Assets/Img/Efficacy/img-secondary-endpoint.png"),
            listing:["COMBOGESIC® IV <span class='primary-color semi-bold'>provided greater pain relief</span> than all 3 comparative treatment groups at the majority of scheduled time points<sup>1&sect;</sup>","More patients treated with COMBOGESIC® IV achieved at least a <span class='primary-color semi-bold'>50% reduction in baseline pain without rescue medication</span> than patients receiving ibuprofen or placebo<sup>1||</sup>","COMBOGESIC® IV <span class='primary-color semi-bold'>delayed the time to first request for rescue medication</span> and <span class='primary-color semi-bold'>reduced the usage rates and consumption of rescue medication</span> over 24 and 48 hours<sup>1||</sup>","<span class='primary-color semi-bold'>Usage rates of supplementary analgesia were lower</span> with COMBOGESIC® IV (75%) compared to ibuprofen (92%), acetaminophen (93%), and placebo (96%) (<i>P</i><0.005)<sup>1</sup>"],
            subtitle:"",
            listing2:[],
            boxdata:"COMBOGESIC® IV offers an <strong><i>advanced</i></strong> strategy to provide multimodal analgesia to patients when an intravenous route of administration is clinically necessary<sup>1</sup>",
            footnote:["<sup>&sect;</sup>According to VAS Pain Intensity, Pain Intensity Differences, and Pain Relief scores.","<sup class='parallel'>||</sup>Rescue medication was available on request as primary oral oxycodone 5-10 mg or secondary IV morphine sulfate 2-4 mg."]
          }],
          id:"sustained-results"
        },
      ],
      StudyDesignData:{
        Title: "Study Design",
        listing: ["COMBOGESIC® IV was studied in a Phase 3, placebo-controlled, prospective, randomized, double-blind, factorial design trial comparing the analgesic efficacy and safety of COMBOGESIC® IV (n=75/276) with acetaminophen alone (n=75/276), ibuprofen alone (n=76/276), and placebo (n=50/276) after bunionectomy surgery<sup>1</sup>","The primary efficacy endpoint was the time-adjusted Sum of Pain Intensity Differences over 48 hours (SPID<sub>48</sub>) and analyzed with each pre-rescue Visual Analogue Scale (VAS) carried forward up to 2 hours<sup>1</sup>","All study medicines were administered to patients as intravenous infusions over 15 minutes every 6 hours over a 48-hour period<sup>1</sup>"],
        boxdata: "COMBOGESIC® IV is already being used in 36 countries<sup>4</sup>",
        calloutsection:[{
          title:"Open Access Bunionectomy Study",
          image: require("@/Assets/Img/Icons/icon-50-percent.svg"),
          description:"The bunionectomy study conducted with COMBOGESIC® IV is an open access document available to download",
          link:"https://doi.org/10.1016/j.clinthera.2019.07.008"
        }]

      },
      HomePageNavData:[
         {
          title: "Ordering COMBOGESIC® IV is simple",
          description:"",
          link: "https://www.hikmadirect.com/login",
          linkText: "ORDER PRODUCT TODAY",
          icon : require("@/Assets/Img/Icons/icon-cart-lg.svg"),
        },
      ]
    };
  },
  computed: {
    ...mapState([
      "userAgent",
      "urls",
      "animationsData",
      "controllerExist",
      "mainMenu",
      "activeSection",
    ]),
    isIE() {
      return this.userAgent.browser.name === "ie";
    },
    routes() {
      return this.$router.options.routes;
    },
  },
  methods: {
    clickAnchor(anchor, offset) {
      if (typeof offset !== "undefined") {
        this.$scrollTo(anchor, 750, {
          container: "body",
          easing: "ease-in",
          offset: offset,
        });
      } else {
        this.$scrollTo(anchor, 750, this.options);
      }
    },
  },
  mounted() {
    this.initScrollDepthAnalytics();
  },
  watch: {
    activeSection(newVal) {},
  },
};
</script>

<style lang="scss" src="./AnalgesicEfficacy.scss" scoped></style>