import { render, staticRenderFns } from "./EfficacySection.vue?vue&type=template&id=cf58da62&scoped=true"
import script from "./EfficacySection.vue?vue&type=script&lang=js"
export * from "./EfficacySection.vue?vue&type=script&lang=js"
import style0 from "./EfficacySection.scss?vue&type=style&index=0&id=cf58da62&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf58da62",
  null
  
)

export default component.exports