var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"headerWrap"},[_c('div',{staticClass:"header",attrs:{"id":"headerMain"}},[_c('div',{staticClass:"header__logo",on:{"click":_vm.scrollTop}},[_c('router-link',{staticClass:"header__logo--desktop",attrs:{"to":{ name: 'home' }}},[_c('img',{staticClass:"icon-logo",attrs:{"src":require("@/Assets/Img/Logos/primary-logo.svg"),"alt":"Combogesic logo"}})])],1),_c('div',{staticClass:"header__right desktop"},[_c('nav',[_c('ul',[_c('li',{staticStyle:{"display":"none"}}),_vm._l((_vm.$router.options.routes),function(route,index){
var _obj;
return (route.name != 'home' && index <= 4)?_c('li',{key:route.name,class:( _obj = {'active': route.name == _vm.$route.name,'submenu' : route.submenu}, _obj[("desktoplink" + index)] = true, _obj )},[_c('router-link',{staticClass:"routelink",attrs:{"to":route.path}},[_c('span',{staticClass:"routelink",domProps:{"innerHTML":_vm._s(route.name)}})]),_c('ul',{staticClass:"submenu"},_vm._l((route.submenu),function(submenus){return _c('li',[_c('router-link',{staticClass:"routelink",attrs:{"to":submenus.path}},[_c('span',{staticClass:"routelink",domProps:{"innerHTML":_vm._s(submenus.name)}})])],1)}),0)],1):_vm._e()})],2)])]),_c('div',{staticClass:"header__right mobile"},[_c('button',{staticClass:"header__menuToggle",class:{ active_menu: this.mobileMenuState },on:{"click":_vm.toggleMenu}},[(!_vm.mobileMenuState)?_c('span',{staticClass:"sr-only"},[_vm._v("expand")]):_vm._e(),(_vm.mobileMenuState)?_c('span',{staticClass:"sr-only"},[_vm._v("collapse")]):_vm._e()])])]),_c('div',{staticClass:"header__collapseWrapper",class:{
    'header__collapseWrapper  header__collapseWrapper--open':
      this.mobileMenuState,
  },attrs:{"id":"headerNav"}},[_c('nav',[_c('ul',{staticClass:"header__dropdown",class:{ isOpen: _vm.isOpen }},[_c('li',{staticStyle:{"display":"none"}}),_vm._l((_vm.$router.options.routes),function(route,index){
  var _obj, _obj$1;
return (route.name != 'home' && index <= 4)?_c('li',{key:route.name,class:( _obj = {'active': route.name == _vm.$route.name,'submenu' : route.submenu}, _obj[("mobilelink" + index)] = true, _obj )},[(route.submenu)?_c('button',{staticClass:"routelink mobilelink",attrs:{"aria-label":"sub menu"},on:{"click":function($event){return _vm.toggleSublist(index)}}},[_c('span',{staticClass:"routelink",domProps:{"innerHTML":_vm._s(route.name)}})]):_vm._e(),(!route.submenu)?_c('router-link',{staticClass:"routelink",attrs:{"to":route.path},on:{"click":_vm.collapseMenu}},[_c('span',{staticClass:"routelink",domProps:{"innerHTML":_vm._s(route.name)}})]):_vm._e(),_c('ul',{staticClass:"submenu",class:( _obj$1 = {'opened': _vm.activeIndex === index}, _obj$1[("mobilesublink" + index)] = true, _obj$1 )},_vm._l((route.submenu),function(submenus){return _c('li',{key:submenus.name},[_c('router-link',{staticClass:"routelink",attrs:{"to":submenus.path},on:{"click":_vm.collapseMenu}},[_c('span',{staticClass:"routelink",domProps:{"innerHTML":_vm._s(submenus.name)}})])],1)}),0)],1):_vm._e()}),_vm._m(0),_c('li',{staticClass:"staticLink"},[_c('a',{attrs:{"href":_vm.urls.PI.link,"target":"_blank"}},[_c('img',{attrs:{"src":require("@/Assets/Img/Icons/icon-pdf-white.svg"),"alt":"PDF Icon"}}),_vm._v(_vm._s(_vm.urls.PI.text))])]),_vm._m(1)],2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"staticLink"},[_c('a',{attrs:{"href":"/#/contact-us"}},[_c('img',{attrs:{"src":require("@/Assets/Img/Icons/icon-connect-white.svg"),"alt":"Connect Icon"}}),_vm._v("Connect With Hikma")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"btnOrder"},[_c('a',{attrs:{"href":"https://www.hikmadirect.com/login","target":"_blank"}},[_c('img',{attrs:{"src":require("@/Assets/Img/Icons/icon-cart.svg"),"alt":"Cart Icon"}}),_vm._v("Order Product Today")])])}]

export { render, staticRenderFns }